<template>
  <div>
    <Header :title="title">
      <template v-slot:btn-actions>
        <b-button
          variant="outline-secondary btn_header"
          class="margin-right"
          @click="newDevice"
        >
          <b-icon-plus-circle />
        </b-button>
      </template>
    </Header>
    <smart-widget-grid :layout="layout">
      <smart-widget slot="0" simple id="widget1">
        <card-temperature />
      </smart-widget>
      <smart-widget slot="1" simple id="widget2">
         <card-bed />
      </smart-widget>
      <smart-widget slot="2" simple>
        <card-temperature-hum />
      </smart-widget>
    </smart-widget-grid>
    <modal-base title="Nuevo dispositivo" :id="id">
      <template v-slot:form >
        <form-device />
      </template>
    </modal-base>
  </div>
</template>

<script>
import { BIconPlusCircle, BButton, BRow, BCol, BContainer, } from "bootstrap-vue";
import Header from "@/components/header/Header";
import ModalBase from "@/components/modal/ModalBase";
import FormDevice from "./components/FormDevice";
import CardBed from "./components/CardBed";
import CardTemperature from "./components/CardTemperature";
import CardTemperatureHum from "./components/CardTemperatureHum";

export default {
  components: {
    Header,
    BButton,
    BIconPlusCircle,
    ModalBase,
    FormDevice,
    CardBed,
    CardTemperature,
    CardTemperatureHum,
  },
  data() {
    return {
      title: "Dashboard General (Configurable)",
      id: "modal-device",
      layout: [
        { x: 0, y: 0, w: 3, h: 4, i: '0' },
        { x: 3, y: 0, w: 3, h: 4, i: '1' },
        { x: 6, y: 0, w: 3, h: 4, i: '2' },
      ]
    }
  },
  methods: {
    newDevice() {
      const me = this;
      me.$bvModal.show(me.id);
    },
  },
  mounted() {
    const widget1 = document.getElementById("widget1");
    const widget2 = document.getElementById("widget2");
    const position1 = widget1.getBoundingClientRect();
    const position2 = widget2.getBoundingClientRect();
    
    const {
      bottom,
      height,
      left,
      right,
      top,
      width,
      x,
      y,
    } = position1;

    widget1.style.bottom = "497.88116455078125px";
    widget1.style.top = "800px";
    widget1.style.y = "278.9405822753906px";
  },
}
</script>

<style lang="css">

.widget-body__content {
  padding: 0 !important;
}

</style>