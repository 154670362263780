<template>
  <div>
    <div class="header__">
      <b-row>
        <b-col md="4" class="d-flex align-items-center pl-25">
          <div class="">
            <div class="title_card_bed">Riesgos</div>
            <div>
              <div class="bar_rish_bed" />
              <div class="bar_rish_bed" />
              <div class="bar_rish_bed" />
            </div>
          </div>
          <b-button
            v-b-tooltip.hover.top="'Tiempo ulcera'"
            size="sm"
            variant="secondary"
            class="btn-icon ml-25 btn_bed"
          >
            <b-icon-clock />
          </b-button>
        </b-col>
        <b-col md="5" class="d-flex align-items-center justify-content-center">
          <div class="title3_card_bed">HAB-101</div>
        </b-col>
        <b-col md="3" class="d-flex align-items-center justify-content-end">
          <b-button
            v-b-tooltip.hover.top="'Temperatura'"
            size="sm"
            variant="secondary"
            class="btn-icon btn_bed"
          >
            24ºC
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="padding_">
      <b-row>
        <b-col sm="6">
          <div class="title2_card_bed">Ronda</div>
          <div>
            <div class="round_bed" />
          </div>
        </b-col>
        <b-col sm="6">
          <div class="title2_card_bed">Exámen</div>
          <div class="text-center">
            <b-progress :max="max" height="1rem" class="progress__" striped>
              <b-progress-bar
                :value="value"
                class="exam"
                :label="`${(value / max) * 100}%`"
              ></b-progress-bar>
            </b-progress>
            <span class="title2_card_bed">2/4</span>
          </div>
        </b-col>
        <b-col sm="6">
          <div class="title2_card_bed">Alimentos</div>
          <div class="text-center">
            <b-progress height="1rem" class="progress__" striped>
              <b-progress-bar :value="value2 * (1 / 3)" class="diet1">
                <strong>D</strong>
              </b-progress-bar>
              <b-progress-bar :value="value2 * (1 / 3)" class="diet2">
                <strong>A</strong>
              </b-progress-bar>
              <b-progress-bar :value="value2 * (1 / 3)" class="diet3">
                <strong>C</strong>
              </b-progress-bar>
            </b-progress>
            <span class="title2_card_bed">2/4</span>
          </div>
        </b-col>
        <b-col sm="6">
          <div class="title2_card_bed">Medicamentos</div>
          <div class="text-center">
            <b-progress :max="max" height="1rem" class="progress__" striped>
              <b-progress-bar
                :value="value"
                class="medicine"
                :label="`${(value / max) * 100}%`"
              ></b-progress-bar>
            </b-progress>
            <span class="title2_card_bed">2/4</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BIconClock,
  BButton,
  VBTooltip,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BIconClock,
    BProgress,
    BProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      max: 100,
      value: 40,
      value2: 100,
    };
  },
};
</script>

<style lang="css">
.card_ {
  border: 1.5px solid #6e6b7b;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.card-header {
  background-color: rgb(236, 234, 234);
  padding: 4px;
  height: 59px;
}

.header__ {
  background-color: rgb(236, 234, 234);
  padding: 4px;
  height: 59px;
}

.padding_ {
  padding: 8px;
}

.title_card_bed {
  font-size: 12px;
  color: #636363;
}

.title2_card_bed {
  font-size: 14px;
  color: #636363;
  font-weight: 700;
}

.title3_card_bed {
  font-size: 22px;
  color: #636363;
  font-weight: 700;
}

.round_bed {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #7367f0;
}

.bar_rish_bed {
  padding: 4px;
  border-radius: 50px;
  background-color: #7367f0;
  margin-bottom: 2px;
  width: 40px;
}

.btn_bed {
  padding: 3px;
  font-size: 18px;
}

.progress__ {
  background-color: #BBBDBA;
}

.exam {
  background-color: #23D160 !important;
}

.diet1 {
  background-color: #23D160 !important;
}

.diet2 {
  background-color: #80A4ED !important;
}

.diet3 {
  background-color: #25CED1 !important;
}

.medicine {
  background-color: #256EFF !important;
}
</style>
