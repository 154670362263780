<template>
  <b-form @submit.prevent="saveData" ref="form">
    <b-row class="mb-2">
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label for="name" class="label_input">Servicio</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Servicio"
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label for="name" class="label_input">Área</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Área"
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Tipo de dispositivo</label>
          <v-select
            :options="areas"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Tipo de asociados</label>
          <v-select
            :options="areas"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end mt-2">
        <b-button
          type="submit"
          class="btn__guardar"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  props: {},
  data() {
    return {
      isBusy: false,
      formData: {},
      areas: [],
      selected: 2,
      options: [
        { text: 'Configurable', value: 1 },
        { text: 'Estándar', value: 2 },
      ]
    }
  },
  validations: {},
  async mounted() {},
  methods: {
    saveData() {}
  },
}
</script>

<style>

</style>