<template>
  <div>
    <div class="header__">
      <div class="title_temperature">Área/Subarea/</div>
      <div class="title_temperature">Dispositivo (Termohigrómetro)</div>
    </div>
    <div class="padding_">
      <b-row>
        <b-col sm="12">
          <div class="d-flex justify-content-center">
            <div class="mr-3">
              <div class="d-flex justify-content-center mb-1">
                <div class="subtitle_temperature mr-2">Min: 20°</div>
                <div class="subtitle_temperature">Max: 60°</div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="box_temperature_hum box1">32°</div>
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-center mb-1">
                <div class="subtitle_temperature mr-2">Min: 20°</div>
                <div class="subtitle_temperature">Max: 60°</div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="box_temperature_hum box2">68°</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="btn_action" v-b-tooltip.hover.top="'Configuración'">
              <b-icon-gear />
            </div>
            <div class="btn_action" v-b-tooltip.hover.top="'Estadisticas'">
              <b-icon-bar-chart-line />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BIconGear,
  BIconBarChartLine,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BIconGear,
    BIconBarChartLine,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      max: 100,
      value: 40,
    };
  },
};
</script>

<style lang="css">
.card_ {
  border: 1.5px solid #6e6b7b;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.card-header {
  background-color: rgb(236, 234, 234);
  padding: 4px;
  height: 59px;
}

.header__ {
  background-color: rgb(236, 234, 234);
  padding: 4px;
  height: 59px;
}

.padding_ {
  padding: 8px;
}

.title_temperature {
  font-size: 16px;
  color: #636363;
  font-weight: 700;
}

.subtitle_temperature {
  font-size: 14px;
  color: #636363;
  font-weight: 700;
}

.box_temperature_hum {
  border: 2px solid black;
  color: white;
  width: 100px;
  height: 86px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box1 {
  background-color: #1e1bf1;
}

.box2 {
  background-color: #f53676;
}

.btn_action {
  cursor: pointer;
}
</style>
